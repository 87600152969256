import { template as template_56ba456cb71b407e9ade6ae85a7006b5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_56ba456cb71b407e9ade6ae85a7006b5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
