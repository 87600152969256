import { template as template_40249af884124b23a0c68764d94a40bc } from "@ember/template-compiler";
const FKLabel = template_40249af884124b23a0c68764d94a40bc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
